require('./image/favicon/android-chrome-192x192.png');
require('./image/favicon/android-chrome-256x256.png');
require('./image/favicon/apple-touch-icon.png');
require('./image/favicon/favicon-32x32.png');
require('./image/favicon/favicon-16x16.png');
require('./image/favicon/mstile-150x150.png');
require('./image/favicon/site.webmanifest');
require('./image/favicon/safari-pinned-tab.svg');

require('./image/logos/simpatico-pr-logo.svg');
require('./image/logos/simpatico-pr-logo-white.svg');
require('./image/logos/simpatico-pr-logo-black.svg');
require('./image/logos/simpatico-badge.png');

require('./image/homepage-banner.png');
require('./image/about-us-banner.jpg');
require('./image/contact-us-banner.jpg');
require('./image/blog-banner.jpg');
require('./image/clients-banner.jpg');
require('./image/work-banner.jpg');
require('./image/services-banner.jpg');
require('./image/thank-you-banner.jpg');
require('./image/notfound.jpg');

require('./image/patrick-barrett.png');
require('./image/natalie-linder.png');
require('./image/matt-walker.png');

require('./image/crowd.jpg');

require('./image/social/facebook-rounded.svg');
require('./image/social/linkedin-rounded.svg');
require('./image/social/twitter-rounded.svg');
require('./image/social/youtube-rounded.svg');

require('./image/social/linkedin.svg');
require('./image/social/twitter.svg');
require('./image/social/youtube.svg');
require('./image/social/facebook.svg');

require('./image/social/twitter-grey.svg');

// Icons Red
require('./image/icons/plane-red.svg');
require('./image/icons/arrow-down-red.svg');
require('./image/icons/arrow-right-red.svg');
require('./image/icons/arrow-left-red.svg');

// Icons Orange
require('./image/icons/leadership-orange.svg');
require('./image/icons/media-relations-orange.svg');
require('./image/icons/strategy-orange.svg');
require('./image/icons/team-orange.svg');
require('./image/icons/social-media-orange.svg');

// Icons White
require('./image/icons/content-marketing-white.svg');
require('./image/icons/social-media-white.svg');
require('./image/icons/arrow-up-white.svg');
require('./image/icons/collapse-white.svg');
require('./image/icons/expand-white.svg');
require('./image/icons/culture-white.svg');
require('./image/icons/leadership-white.svg');
require('./image/icons/international-white.svg');

// Company Icons
require('./image/companies/merkle.png');
require('./image/companies/sign-salad.png');
require('./image/companies/gumgum.png');
require('./image/companies/audienceproject.png');
require('./image/companies/infectious-media.png');
require('./image/companies/neuro-insight.png');
require('./image/companies/the7stars.png');

// Homepage Images
require('./image/homepage-phone.jpg');
require('./image/homepage-vr.jpg');
require('./image/homepage-lights.jpg');
